$theme-colors: (
  "primary": #407d35,
);

@import '../node_modules/bootstrap/scss/bootstrap.scss';

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

code {
  font-family: 'Roboto Mono', monospace;
}

.language-select button {
  border: none;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,400&family=Roboto+Mono&display=swap');
