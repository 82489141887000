/*
* Misc
*/

#tab-scroller > a {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


/*
* Dropzone
*/

.dropzone-center {
    text-align: center;
    width: 700px;
    height: 500px;
    margin: auto;
    margin-top: 100px;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
    border-color: lightgrey;
    cursor: pointer;
    font-size: 35px;
    color: darkgrey;
}

.upload-icon {
    font-size: 300px;
    color: lightgrey;
    padding-top: 60px;
}

.dropzone-corner {
    background-color: black;
    width: 0%;
    height: 0%;
    display: block;
    bottom: 0px;
    left: 0px;
}

.dropzone-corner-dragging {
    animation-fill-mode: forwards;
    animation-name: dropzone-corner-opening;
    animation-duration: 600ms;
    transform-origin: left bottom;
}

.dropzone-corner-not-dragging {
    animation-fill-mode: forwards;
    animation-name: dropzone-corner-closing;
    animation-duration: 600ms;
    transform-origin: left bottom;
    bottom: 0;
    left: 0;
}

@keyframes dropzone-corner-opening {
    from {
        width: 0;
        height: 0;
    }
    to {
        width: 100%;
        height: 100%;
    }
}

@keyframes dropzone-corner-closing {
    from {
        width: 100%;
        height: 100%;
    }
    to {
        width: 0;
        height: 0;
    }
}

.dropzone-corner-div {
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 40%;
    height: 50%;
}

.dropzone-popup {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(10, 10, 10, 0.8);
    width: 100%;
    height: 100%;
}

.dropzone-popup .box {
    width: 50%;
    height: 50%;
    opacity: 1;
    margin: auto;
    margin-top: 200px;
}

/*
 * Datasheet
 */

.datasheet-table th, .datasheet-table td {
    vertical-align: top;
}

.datasheet-table td {
    border-right: 1px solid lightgrey;
}

.datasheet-table td:last-child {
    border-right: none;
}

.datasheet-table tr th.th-header {
    padding: 0;
    vertical-align: middle;
}

.table-column {
    width: 10%;
}

.header-button {
    display: block;
    text-align: center;
    height: 56px;
    width: 100%;
    background: white;
    border: none;
    position: relative;
    outline: none;
    min-width: 155px;
}

.header-button:hover {
    background: lightgray;
    cursor: pointer;
}

.header-button:active {
    background: darkgrey;
    cursor: pointer;
    outline: none;
}

.header-button:focus {
    outline: 0;
}

.header-caret {
    right: 3%;
    position: absolute;
    font-size: 20px;
    color: grey;
}

.p-slim {
    border: 0px;
    margin: 0px;
    padding: 0px;
}

.rel-p {
    position: absolute;
    width: 100%;
    top: 34px;
}

.bottom-bar {
    position: fixed;
    bottom: 0px;
    width: 100%;
}

.bottom-bar-div-center {
    width: 50%;
    margin: auto;
}

.nowrap {
    white-space: nowrap;
}

.go-button {
    min-width: 100px;
    float: right;
}

.go-check {
    margin-left: 10px;
}

.logo {
    height: 40px;
    width: auto;
}

.top-bar-button {
    min-width: 100px;
}

.top-bar {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1;
}

.rejected-file {
    color: white;
    border: 5px solid #dc3545!important;
    border-radius: 5px;
    background-color: #dc3545!important;
}

.rejected-files-container {
    position: absolute;
    width: 100%;
    margin: auto;
    bottom: 100px;
}

.margin-tab-bar {
    margin-bottom: 45px;
}

.btn.page-link {
    border-radius: 0;
}

/*
 * Sheet tabs
 */

#tabs {
    border-top: 3px solid #aaa;
}

.tab-name {
    cursor: pointer;
    display: inline-block;
    border: 1px solid #ccc;
    background: #f8f9fa;
    color: #666666;
    border-right-width: 0px;
    padding: 5px 7px;
    z-index: 0;
    /*
    padding-left: 6px;
    padding-right: 18px;
    */
    position: relative;
    top: -9px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    /* Disable outline and highlight/select */
    outline: 0;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.tab-name:last-child {
    border-right-width: 1px;
}

.tab-name:hover {
    background: #fdfdfd;
}

.tab-name-active {
    /*font-weight: bold;*/
    /* Make sure box shadow is not overlapped */
    z-index: 1;
    border-color: #cccccc;
    background: #ffffff;
    color: #222222;
    border-top: none;
    box-shadow: 0px 0px 2px rgba(53, 53, 53, 0.3);
    border-right-width: 0px;
    top: -10px;
    padding-bottom: 6px;
    cursor: default;
}

.active-tab {
    height: 14px;
    top: -14px;
    position: absolute;
    border-left: 1px solid darkgrey;
    border-right: 1px solid darkgrey;
    padding: 0;
    left: -1px;
    right: -1px;
}

.tab-delete {
    cursor: pointer;
    font-size: 0.7em;
}

.tab-delete:hover {}

.new-tab {
    margin: 5px;
    position: relative;
}

/*
 *
 */

.popup {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(10, 10, 10, 0.8);
    width: 100%;
    height: 100%;
}

.popup .container {
    width: 50%;
    height: 50%;
    margin: auto;
    margin-top: 200px;
}

.my-modal {
    width: 50%;
    margin: auto;
}

.new-tab-input {
    position: absolute;
    width: 100%;
    height: 38px;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

/*
 *
 *
 */

.solvers {
    padding: 1em;
}

/*
 * Backend lost connection
 */

#backend-lost-connection-banner {
    text-align: center;
    color: #ffffff;
    background-color: #cc0000;
}

/*
 * FontAwsome stuff
 */

.icon-spin {
    -webkit-animation: icon-spin 2s infinite linear;
    animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}



/*
 * DatasheetUnderstander
 */

.datasheet-understander.expected-column:hover {
    text-decoration: underline dashed gray;
}

.solver-card {
    margin-bottom: 1rem;
}

h1, h2, h3 {
    margin-top: 1em;
}