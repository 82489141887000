@media all and (min-width: 480px) {
    .Login form {
        margin: 0 auto;
        max-width: 320px;
    }

    form .alert {
        margin-top: 1em;
    }
}

@media all and (max-width: 480px) {
    .Login {
        padding: 0 1em;
    }
}
