input[type="text"] {
  margin-bottom: 5px;
}

.spin {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  transform: translate3d(-50%, -50%, 0);
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
